import {
    ICalcFeeRequestProps,
    ICalcFeeResponse,
    ICalcInvestorRepaymentFeeRequest,
    ICalcRepaymentFeeRequest,
    ICalcRepaymentFeeResponse,
    IRequest,
    ResponseItem,
} from '@dltru/dfa-models'

import { requestDecorator } from '..'
import { HttpCode, HttpMethod } from '../../types/types'

export async function calculateFeeService(
    data: ICalcFeeRequestProps,
): Promise<IRequest<ResponseItem<ICalcFeeResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.TariffCalculateFee,
        HttpMethod.POST,
        HttpCode.SUCCESS_200,
        { data },
    )
}
export async function calculateRepaymentFeeService(
    params: ICalcRepaymentFeeRequest,
): Promise<IRequest<ResponseItem<ICalcRepaymentFeeResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.CalculateRepaymentFee,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function calculateInvestorRepaymentFee(
    params: ICalcInvestorRepaymentFeeRequest,
): Promise<IRequest<ResponseItem<ICalcFeeResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.CalculateInvestorRepaymentFee,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params },
    )
}

export async function calculateMatureRepaymentFee(
    asset_id: number,
): Promise<IRequest<ResponseItem<ICalcFeeResponse>>> {
    return requestDecorator.call(
        this,
        this.paths.CalculateMatureRepaymentFee,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params: { asset_id } },
    )
}

export async function calculateOrdersAcceptFee(
    order_id: number,
): Promise<IRequest<ResponseItem<{item: number}>>> {
    return requestDecorator.call(
        this,
        this.paths.TransferDfaOrdersCalculateAcceptFee,
        HttpMethod.GET,
        HttpCode.SUCCESS_200,
        { params: { order_id } },
    )
}